import '../styles/Services.css'

import chip from '../images/chip.png'
import robot from '../images/robot.png'
import mechatronics from '../images/mechatronics.png'
import edu from '../images/edu.png'


function Services() {
  return (
    <div className="services">

      <div className="heading">
        <h1>Services that we Offer</h1>
        <p>Providing secure and quality services</p>
      </div>

      <div className="boxes">

        <div className="box">
          <img src={chip} alt="" style={{ backgroundColor: "#36CC72" }}/>
          <h2>Embedded solutions</h2>
          <p>DOIT Innovations understands the pivotal role that embedded technology plays in shaping our connected world. Our dedicated team of experts is committed to delivering solutions that exceed expectations, providing you with a competitive edge in today's fast-paced technological landscape. We offer a wide range of embedded solutions tailored to meet the specific needs of your projects. </p>
        </div>

        <div className="box">
          <img src={mechatronics} alt="" style={{ backgroundColor: "#FFCA40" }} />
          <h2>Mechatronics</h2>
          <p>Welcome to DOIT innovations, where innovation meets integration. Our specialized team merges mechanical and electrical engineering to create seamless automation solutions. With expertise across disciplines, we deliver tailored mechatronic systems that optimize efficiency and performance. Join us in shaping the future of automation and robotics.</p>
        </div>

        <div className="box">
          <img src={edu} alt="" style={{ backgroundColor: "#FF6D3D" }} />
          <h2>Edu-Tech kits</h2>
          <p>At DOIT innovations, we pioneer modern education with innovative kits tailored for immersive learning. Our expert team delivers cutting-edge resources, inspiring curiosity and academic excellence. With diverse offerings, we engage learners of all ages, nurturing creativity and critical thinking. Join us to revolutionize education and empower future generations for success.</p>
        </div>

        <div className="box">
          <img src={robot} alt="" style={{ backgroundColor: "#8b98ac" }} />
          <h2>Robotic solutions</h2>
          <p>At DOIT innovations, we recognize the transformative potential of robotics in revolutionizing industries and enhancing human capabilities. Our passionate team of robotics specialists is dedicated to crafting innovative solutions that go beyond traditional boundaries, empowering you to stay ahead in the dynamic realm of robotics. Partner with us to unlock endless possibilities and redefine the future of automation.</p>
        </div>


      </div>
    </div>
  )
}

export default Services
