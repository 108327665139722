import '../styles/Contact.css'

import { MdOutlineHeadphones } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { MdOutlineLocationOn } from "react-icons/md";


const phoneNumber = '+91 8078 33 27 01';
const emailAddress = 'info@doitinnovations.in'

function Contact() {
  return (
    <div className="contact">
      <div className="address">
        <h4>Contact Address:</h4>
        <p>Do it innovations</p>
        <div className="phone">
          <MdOutlineHeadphones className='phone-icon' />
          <a className="phone-number" href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </div>
        <div className="email">
          <MdOutlineMail className='email-icon' />
          <a className="mail-id" href={`mailto:${emailAddress}`}> {emailAddress}</a>
        </div>
        <div className="location">
          <MdOutlineLocationOn className='location-icon' />
          <h6 className='place'>Govt. Eng College Thrissur.Main block 1st floor</h6>
        </div>

      </div>
      <div className="map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d245.14487331569552!2d76.22431707618243!3d10.55427170046573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7ef4684afb2ff%3A0xcd76b1b5577c007f!2sDoit%20Innovations%20LLP!5e0!3m2!1sen!2sin!4v1708097472328!5m2!1sen!2sin"
          width="100%"
          height="100%"
          style={{ border: "0", borderRadius: "2%" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade">

        </iframe>
      </div>
    </div>
  )
}

export default Contact
