import '../styles/About.css'

import features1 from '../images/features-1.png'
import features2 from '../images/features-2.png'
import features3 from '../images/features-3.png'
import features4 from '../images/features-4.png'
import features5 from '../images/features-5.png'
import features6 from '../images/features-6.png'

function About() {
  return (
    <div className="about">
      <h1>About us</h1>
      <p><strong>DOIT Innovations</strong> is a highly trusted and responsible development company,with over many years of experience ,whose primary focus is to provide customers with services of the highest quality.</p>

      <div className="about-cards">

        <div className="card">
          <img src={features1} alt=""  className='image'/>
          <h3>Client is king</h3>
          <p>Our management team makes sure that every idea shared by the customer is smoothly communicated to the developers. So that the vision of the customer is realized.</p>
        </div>

        <div className="card">
          <img src={features2} alt=""  className='image'/>
          <h3>Trailblazing technologies</h3>
          <p>We constantly strive to make an effort for always incorporating the latest technologies available, with the services we provide. This fervent desire to always stay on top of the game has helped us in delivering top-quality products.</p>
        </div>

        <div className="card">
          <img src={features3} alt="" className='image' />
          <h3>Unwaivering support</h3>
          <p>Our services do not stop at the successful delivery of the product, as we have taken it as a responsibility to make sure that our clients are fully familiarized with the product by providing dedicated technical support.</p>
        </div>

        <div className="card">
          <img src={features4} alt="" className='image'/>
          <h3>Quality is our middle name</h3>
          <p>The developers put in honest and dedicated efforts in each phase of development, from design to delivery. So that our clients will not have any second thoughts on the quality of the product that they have received.</p>
        </div>

        <div className="card">
          <img src={features5} alt="" className='image' />
          <h3>Always on time</h3>
          <p>Meticulously planning each phase of the development of a product from its inception makes sure that we deliver the finished product within a timeframe that the client desires.</p>
        </div>

        <div className="card">
          <img src={features6} alt=""  className='image'/>
          <h3>Uncompromised security</h3>
          <p>Security is one of the most important aspects of any service that is available in today's market.We make sure that each product that we provide has gone through many phases of testing to make sure that the final product is as secure as it can be.</p>
        </div>

      </div>
    </div>
  )
}

export default About
