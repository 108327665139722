import React, { useEffect } from 'react';
import '../styles/Home.css';
import mainPic from '../images/main-pic.png';

function Home() {
  useEffect(() => {
    const parallax = (e) => {
      document.querySelectorAll(".object").forEach((move) => {
        const movingValue = parseFloat(move.getAttribute("data-value"));
        const x = (e.clientX * movingValue) / 300;
        const y = (e.clientY * movingValue) / 300;

        move.style.transition = 'transform 0.2s ease';
        move.style.transform = `translateX(${x}px) translateY(${y}px)`;
      });
    };

    document.addEventListener('mousemove', parallax);

    return () => {
      document.removeEventListener('mousemove', parallax);
    };
  }, []);

  return (
    <div className="home">
      <div className="heading-section">
        <h1>DO IT INNOVATIONS.</h1>
        <h2>Unlock the potential,</h2> 
        <h2>One step empowered at a time!</h2>
      </div>

      <div className="mainPic">
        <img src={mainPic} alt="" data-value="3" className="object" />
      </div>
    </div>
  );
}

export default Home;
