import '../styles/Footer.css'
import { Link } from "react-scroll";


function Footer() {
  return (
    <div className="footer">
      <div className="rectangle">
        <p>Let's Make Something Amazing Together</p>
        <Link to="contact" spy={true} smooth={true} offset={-130} duration={1000} >Get Started....</Link>
      </div>
    </div>
  )
}

export default Footer
